<template>
  <div class="Login" style="margin-bottom: 50px; width: 100%; text-align:center;">
    <img :src="this.getEndpoint+this.defaults.logoPath" width="90%;" style="margin-top: 50px; margin-bottom: 50px; text-align: center" alt="">
    <h1 style=" text-align: center">{{defaults.applicationName}}</h1>
    <h3 style=" text-align: center">Login</h3>
    <v-container v-if="this.loader">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-container>
    <v-container v-else style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols=12>
            <v-text-field
              label="Username"
              type="text"
              :rules="usernameRules"
              required
              autofocus
              v-model="username"
            ></v-text-field>
          </v-col>
          <v-col cols=12>
            <v-text-field
              label="Password"
              type="password"
              inputmode="numeric"
              :rules="passwordRules"
              required
              v-model="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12>
            <v-btn :disabled="!valid" color="success" @click="login">Login</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-snackbar
      v-model="snackBar"
      :timeout="snackTime"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import axios from 'axios';
// axios.defaults.withCredentials = true;
import {mapGetters, mapMutations} from "vuex";
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      loader: false,
      snackBar: false,
      snackText: '',
      snackTime: 3000,
      username: '',
      password: '',
      valid: false,
      usernameRules: [
        v => !!v || 'Username is required',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
    }
  },
  mounted(){
    console.log("Login page")
  },
  computed: {
    ...mapGetters(['getEndpoint', 'defaults'])
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'removeIds', 'lockScanBus', 'unlockScanBus', 'setBranch', 'activateScanSense']),
    async login(){
      try {
        if(this.$refs.form.validate()){
          this.loader = true;
          let res = await axios.post(`${this.getEndpoint}/auth/login`, {username: this.username, password: this.password})
          if(res.data.success){
            this.setUser(res.data.user)
            this.setBranch(res.data.user.metadata.branchData.main)
            this.unlockScanBus()
            this.activateScanSense()
            this.$router.push({path: "/"})
          }else{
            this.snackText = "❌ Username or Password Incorrect "
            this.snackBar = true;
          }
        }
      } catch (e) {
        console.log(e)
        this.snackText = "❌ Username or Password Incorrect "
        this.snackBar = true;
      } finally {
        this.loader = false;
      }
    }
  }
}
</script>
